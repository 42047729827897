import React from 'react';

const EndUserPrivacy = () => {
  return (
    <div className="container mx-auto p-8 max-w-4xl">
      <h1 className="text-4xl font-bold mb-8 text-center">End User Privacy Policy</h1>
      
      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Introduction</h2>
        <p className="text-xl mb-4">Welcome to ZipZap Foods. We value your privacy and are committed to protecting your personal information. This privacy policy is designed to help you understand how we collect, use, and safeguard your data.</p>
        <p className="text-xl mb-4"><strong>Important Notice:</strong> This application is intended for users who are 18 years of age or older. By using this app, you confirm that you are at least 18 years old.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Information We Collect</h2>
        <ul className="list-disc list-inside text-xl space-y-4">
          <li><strong>Personal Information:</strong> We collect personal information such as your name, email address, phone number, and delivery address to facilitate food ordering and delivery.</li>
          <li><strong>Payment Information:</strong> To process your orders, we collect payment details, including credit card information or other payment method data. This information is securely processed through our payment partners.</li>
          <li><strong>Usage Information:</strong> We gather data about how you use our app, including your order history, favorite restaurants, dietary preferences, and interaction with our features.</li>
          <li><strong>Location Data:</strong> With your consent, we collect your device's location to provide location-based services, such as finding nearby restaurants and optimizing delivery routes.</li>
          <li><strong>Device Information:</strong> We collect information about the device you use to access our app, including the device type, operating system, and unique device identifiers.</li>
          <li><strong>Camera Access:</strong> Our app may request access to your device's camera to enable features like scanning QR codes for promotions or uploading food photos. This access is used solely for the intended purpose and with your explicit permission.</li>
          <li><strong>Gallery Access:</strong> We may request access to your device's photo gallery to allow you to upload images, such as for reviews or profile pictures. This access is granted only with your consent.</li>
          <li><strong>Notifications:</strong> We send push notifications about your orders, promotions, and important updates. You can manage these through your device settings.</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">How We Use Your Information</h2>
        <p className="text-xl mb-4">We use the information we collect for various purposes, including:</p>
        <ul className="list-disc list-inside text-xl space-y-2">
          <li>Processing and fulfilling your food orders</li>
          <li>Providing customer support and responding to your inquiries</li>
          <li>Improving our app's functionality and user experience</li>
          <li>Personalizing your experience with tailored recommendations and offers</li>
          <li>Analyzing usage patterns to enhance our services</li>
          <li>Detecting and preventing fraudulent activities</li>
          <li>Complying with legal obligations</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Sharing of Information</h2>
        <p className="text-xl mb-4">We may share your information with:</p>
        <ul className="list-disc list-inside text-xl space-y-2">
          <li>Restaurants and delivery partners to fulfill your orders</li>
          <li>Payment processors to handle transactions</li>
          <li>Analytics and service providers who help us improve our app</li>
          <li>Legal authorities when required by law or to protect our rights</li>
        </ul>
        <p className="text-xl mt-4">We do not sell your personal information to third parties for marketing purposes.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Data Security</h2>
        <p className="text-xl mb-4">We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction. These measures include:</p>
        <ul className="list-disc list-inside text-xl space-y-2">
          <li>Encryption of sensitive data in transit and at rest</li>
          <li>Regular security audits and vulnerability assessments</li>
          <li>Strict access controls and authentication procedures</li>
          <li>Employee training on data protection and privacy practices</li>
        </ul>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Data Retention</h2>
        <p className="text-xl mb-4">We retain your personal information for as long as necessary to provide our services, comply with legal obligations, resolve disputes, and enforce our agreements. The specific retention period may vary depending on the type of data and applicable laws.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Your Rights and Choices</h2>
        <p className="text-xl mb-4">You have certain rights regarding your personal information:</p>
        <ul className="list-disc list-inside text-xl space-y-2">
          <li>Access and review your personal information</li>
          <li>Request corrections to inaccurate or incomplete data</li>
          <li>Request deletion of your personal information</li>
          <li>Object to or restrict certain processing activities</li>
          <li>Withdraw consent for data collection and use</li>
          <li>Request a copy of your data in a portable format</li>
        </ul>
        <p className="text-xl mt-4">To exercise these rights, please contact us using the information provided at the end of this policy.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Cookies and Tracking Technologies</h2>
        <p className="text-xl mb-4">We use cookies and similar tracking technologies to enhance your experience, analyze usage patterns, and deliver personalized content. You can manage your cookie preferences through your browser settings.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Third-Party Links and Services</h2>
        <p className="text-xl mb-4">Our app may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites. We encourage you to review their privacy policies before providing any personal information.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Changes to This Policy</h2>
        <p className="text-xl mb-4">We may update this privacy policy from time to time to reflect changes in our practices or for legal, operational, or regulatory reasons. We will notify you of any significant changes through the app or via email. We encourage you to review this policy periodically.</p>
      </section>

      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Contact Information</h2>
        <p className="text-xl mb-4">If you have any questions, concerns, or requests regarding this privacy policy or our data practices, please contact us at:</p>
        <p className="text-xl"><strong>Email:</strong> <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a></p>
        <p className="text-xl"><strong>Address:</strong> Savorsynth Foodtech Private Limited,Bangalore,Karnataka,India</p>
      </section>

      <footer className="text-center text-gray-600 mt-16">
        <p>Last updated: {new Date().toLocaleDateString()}</p>
      </footer>
    </div>
  );
};

export default EndUserPrivacy;