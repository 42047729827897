import React from "react";
import PropTypes from "prop-types";
import appleLogo from "../assets/apple.png";
import googleLogo from "../assets/google.png";

const Footer = ({ logo }) => (
  <footer style={{ backgroundColor: "#f8f9fa", padding: "3rem 0", color: "#333" }}>
    <div style={{ 
      maxWidth: "1200px", 
      margin: "0 auto", 
      display: "flex", 
      justifyContent: "space-between", 
      alignItems: "center",
      flexWrap: "wrap"
    }}>
      {/* Logo Section */}
      <div style={{ flex: "1 1 200px", marginBottom: "2rem", textAlign: "left" }}>
        <a href="/" style={{ display: "inline-block", marginBottom: "1rem" }}>
          <img src={logo} alt="ZipZap logo" style={{ maxWidth: "100px", height: "auto" }} />
        </a>
        <p style={{ fontSize: "0.9rem" }}>&copy; 2024 ZipZap, Inc. All rights reserved.</p>
      </div>

      {/* Contact Us Section */}
      <div style={{ flex: "1 1 200px", marginBottom: "2rem", textAlign: "left" }}>
        <h3 style={{ fontSize: "1.2rem", fontWeight: "bold", marginBottom: "1rem" }}>Contact Us</h3>
        <address style={{ fontStyle: "normal", lineHeight: "1.6", color: "#555" }}>
          <p>Bangalore, India</p>
          <p>
            <a 
              href="tel:6383522927" 
              style={{ color: "#007bff", textDecoration: "none", transition: "color 0.3s" }} 
              onMouseOver={(e) => e.target.style.color = "#0056b3"} 
              onMouseOut={(e) => e.target.style.color = "#007bff"}
            >
              6383522927
            </a>
          </p>
          <p>
            <a 
              href="mailto:sundaranbu@zipzap.live" 
              style={{ color: "#007bff", textDecoration: "none", transition: "color 0.3s" }} 
              onMouseOver={(e) => e.target.style.color = "#0056b3"} 
              onMouseOut={(e) => e.target.style.color = "#007bff"}
            >
              sundaranbu@zipzap.live
            </a>
          </p>
        </address>
      </div>

      {/* Highlighted Download App Section */}
      <div style={{ 
        flex: "1 1 300px", 
        marginBottom: "2rem", 
        padding: "1.5rem", 
        backgroundColor: "#e9f5ff", 
        borderRadius: "8px",
        textAlign: "center",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
      }}>
        <h3 style={{ fontSize: "1.4rem", fontWeight: "bold", marginBottom: "1rem", color: "#007bff" }}>Download Our App</h3>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <a 
            href="https://apps.apple.com/in/app/zipzap-food/id6639614658" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#333", textDecoration: "none", transition: "color 0.3s", padding: "0.5rem", border: "2px solid #007bff", borderRadius: "5px", backgroundColor: "#fff" }} 
            onMouseOver={(e) => {e.target.style.backgroundColor = "#007bff"; e.target.style.color = "#fff";}} 
            onMouseOut={(e) => {e.target.style.backgroundColor = "#fff"; e.target.style.color = "#333";}}
          >
            <img src={appleLogo} alt="App Store" style={{ width: "24px", marginRight: "10px" }} />
            Download on the App Store
          </a>
          <a 
            href="https://play.google.com/store/apps/details?id=com.zipzap.end_user" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "#333", textDecoration: "none", transition: "color 0.3s", padding: "0.5rem", border: "2px solid #007bff", borderRadius: "5px", backgroundColor: "#fff" }} 
            onMouseOver={(e) => {e.target.style.backgroundColor = "#007bff"; e.target.style.color = "#fff";}} 
            onMouseOut={(e) => {e.target.style.backgroundColor = "#fff"; e.target.style.color = "#333";}}
          >
            <img src={googleLogo} alt="Google Play" style={{ width: "24px", marginRight: "10px" }} />
            Get it on Google Play
          </a>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default Footer;
