import React from 'react';

const RestaurantPrivacy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-6">ZipZap Restaurant Partner Privacy Policy</h1>
      <p className="text-2xl mb-4">
        <strong>Introduction:</strong> We value your privacy and are committed to protecting your personal information. 
        This app, ZipZap Restaurant Partner, including all its services and operations, is provided under SAVORSYNTH FOODTECH PRIVATE LIMITED.
      </p>
      
      <h2 className="text-4xl font-semibold mt-10 mb-2">Age Restriction</h2>
      <p className="text-2xl mb-4">
        <strong>ZipZap Restaurant Partner is intended for use by individuals who are at least 18 years old.</strong> By using this app, you confirm that you are at least 18 years of age. If we learn that we have collected personal information from someone under 18, we will delete that information promptly.
      </p>

      <h2 className="text-4xl font-semibold mt-10 mb-2">Information Collection</h2>
      <ul className="list-disc list-inside text-2xl mb-4">
        <li><strong>Business Information:</strong> We collect business information such as name, contact details, and payment information.</li>
        <li><strong>Usage Information:</strong> We collect information about your use of the app, including order history and preferences.</li>
        <li><strong>Camera Access:</strong> We may request access to your device's camera to allow you to capture photos of your menu items or dishes. This access is granted only with your consent.</li>
        <li><strong>Gallery Access:</strong> We may request access to your device's gallery to enable you to upload images of your dishes. This access is granted only with your explicit consent.</li>
        <li><strong>Notifications:</strong> We may send you notifications regarding updates, promotions, or important information about the app. You can manage these preferences in your device settings.</li>
        <li><strong>Location Data:</strong> We may collect and process information about your precise location with your consent. This helps us provide location-based services for your restaurant.</li>
        <li><strong>Device Information:</strong> We collect information about the device you use to access our app, including hardware model, operating system, unique device identifiers, and mobile network information.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Use of Information</h2>
      <p className="text-2xl mb-4">We use your information to:</p>
      <ul className="list-disc list-inside text-2xl mb-4">
        <li>Provide, maintain, and improve our services</li>
        <li>Process and manage orders for your restaurant</li>
        <li>Communicate with you about your account, orders, and app updates</li>
        <li>Analyze usage patterns to enhance user experience</li>
        <li>Comply with legal obligations</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Sharing of Information</h2>
      <p className="text-2xl mb-4">We do not share your personal information with third parties except as required by law or to provide our services.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Data Security</h2>
      <p className="text-2xl mb-4">We implement security measures to protect your information from unauthorized access or disclosure.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Data Retention and Deletion</h2>
      <p className="text-2xl mb-4">We retain your information as long as necessary to fulfill the purposes outlined in this policy. You can request deletion of your account and associated data by contacting us. Please note that some information may be retained for legal or legitimate business purposes.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">International Data Transfers</h2>
      <p className="text-2xl mb-4">Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place to protect your information in compliance with applicable data protection laws.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">User Rights</h2>
      <p className="text-2xl mb-4">Depending on your location, you may have the following rights:</p>
      <ul className="list-disc list-inside text-2xl mb-4">
        <li>Access and receive a copy of your personal information</li>
        <li>Rectify inaccurate or incomplete information</li>
        <li>Request deletion of your personal information</li>
        <li>Restrict or object to certain processing of your data</li>
        <li>Data portability</li>
      </ul>
      <p className="text-2xl mb-4">To exercise these rights, please contact us using the information provided below.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Changes to Policy</h2>
      <p className="text-2xl mb-4">We may update this policy from time to time. We will notify you of significant changes through the app.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Contact Information</h2>
      <p className="text-2xl mb-4">For any queries or to exercise your rights, contact us at:</p>
      <p className="text-2xl mb-4">
        Email: <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a><br />
        Address: Bangalore, India<br />
        Phone: +91 6383522927
      </p>
      
      <footer className="text-2xl mt-10">
        <p className="text-center">© {new Date().getFullYear()} SAVORSYNTH FOODTECH PRIVATE LIMITED. All rights reserved.</p>
        <p className="text-center mt-2">Last updated: {new Date().toDateString()}</p>
      </footer>
    </div>
  );
};

export default RestaurantPrivacy;
