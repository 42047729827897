import React from 'react';
import chef1 from '../assets/img/hotel_thirtahllai.jpg';
import chef2 from '../assets/img/Sarah Delicacy.jpeg';
import chef3 from '../assets/img/annapoorna_restaurants.jpg';

const chefs = [
  { name: 'Hotel Thirthahalli', bio: 'HSR Layout, Bangalore', imgSrc: chef1 },
  { name: 'Sarah Delicacy', bio: 'Vignan Nagar, Bangalore', imgSrc: chef2 },
  { name: 'Annapoorna Restaurant', bio: 'Kalasapakkam, Thiruvannamalai', imgSrc: chef3 },
];

const ChefsSection = () => (
  <section id="meals" className="section-meals">
    <div className="container centered">
      <span className="subheading">Culinary Talents</span>
      <h2 className="heading-secondary">Discover Our Partner Restaurants</h2>
    </div>
    <div className="container grid grid-cols-3 margin-bottom-md ">
      {chefs.map((chef, index) => (
        <div key={index} className="chef-profile">
          <img src={chef.imgSrc} alt={chef.name} className="chef-img" />
          <h3 className="chef-name">{chef.name}</h3>
          <p className="chef-bio">{chef.bio}</p>
        </div>
      ))}
    </div>
  </section>
);

export default ChefsSection;
