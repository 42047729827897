import React from "react";

const ContactUs = () => {
  return (
    <div
      className="contact-us-page"
      style={{
        maxWidth: "800px",
        margin: "0 auto",
        padding: "20px",
        fontFamily: "'Arial', sans-serif",
        fontSize: '20px'
      }}
    >
      <h1 style={{ textAlign: "center", color: "#333" }}><b>Contact Us</b></h1>
      <p style={{ textAlign: "center", color: "#555" }}>
        If you have any questions or need assistance, we're here to help. Please
        use the form below to get in touch with us!
      </p>

      <div
        className="contact-details"
        style={{
          backgroundColor: "#f9f9f9",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
          marginTop: "20px",
          display: "flex",

        }}
      >
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.9529251384733!2d77.6790880258572!3d12.974862764805136!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae116164d57ccd%3A0x5571d541a1d6bd4!2sVignan%20Nagar%2C%20Doddanekundi%2C%20Doddanekkundi%2C%20Bengaluru%2C%20Karnataka!5e0!3m2!1sen!2sin!4v1723739324743!5m2!1sen!2sin"
            width="300"
            height="200"
            frameborder="0"
            style={{ border: "0", borderRadius: "8px" }}
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
        <div className="pl-10">
          <h2 style={{ color: "#333" }}><b>Our Office:</b></h2>
          <address style={{ lineHeight: "1.6", color: "#555" }}>
            <p>Bangalore, India</p>
            <p>Phone: +91 63835 22927</p>
            <p>
              Email:{" "}
              <a href="mailto:sundaranbu@zipzap.live" style={{ color: "#007BFF" }}>
                sundaranbu@zipzap.live
              </a>
            </p>
          </address>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
