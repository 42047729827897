import React from 'react';
import turboStart from '../assets/img/turbostart.png';

const FeaturedSection = () => (
  <section className="section-featured">
    <div className="container mx-auto">
      <h2 className="heading-featured-in">As Featured in</h2>
      <div className="logos">
        <img src={turboStart} alt="brand-logo" className="min-w-[100px] min-h-[100px]" />
      </div>
    </div>
  </section>
);

export default FeaturedSection;
