import React from 'react';

const RestaurantTerms = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-6">Restaurant - Terms of Service</h1>
      <p className="text-2xl"><strong>Introduction:</strong> Welcome to our partner restaurant app. By using our services, you agree to the following terms and conditions.</p>
      
      <h2 className="text-4xl font-semibold mt-10 mb-2">Eligibility</h2>
      <p className="text-2xl">You must be a registered business to use this app.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Account Registration</h2>
      <p className="text-2xl">You must register an account to receive orders. Ensure your information is accurate and updated.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Order Management</h2>
      <p className="text-2xl">You agree to accept and process orders promptly and accurately.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Payment Terms</h2>
      <ul className="list-disc list-inside text-2xl">
        <li><strong>Methods:</strong> Cash on Delivery, UPI</li>
        <li><strong>Settlement:</strong> Payments will be credited to your account on a daily basis.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Delivery</h2>
      <p className="text-2xl">You are responsible for preparing orders within the specified time frame for delivery.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Quality and Compliance</h2>
      <p className="text-2xl">You agree to maintain high standards of food quality and hygiene and comply with all relevant regulations.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">User Conduct</h2>
      <p className="text-2xl">You agree not to misuse the app or engage in any activities that may harm other users or the service.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Liability</h2>
      <p className="text-2xl">Our liability is limited to the amount paid for your order. We are not responsible for any indirect or consequential losses.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Modifications</h2>
      <p className="text-2xl">We reserve the right to modify these terms at any time. Continued use of the app constitutes acceptance of the changes.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Contact Information</h2>
      <p className="text-2xl">For any queries, contact us at <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>.</p>
    </div>
  );
};

export default RestaurantTerms;
