import React from "react";
import homePageRestaurant from "../assets/img/home-page-restaurant.png";
import homePageTiffin from "../assets/img/home-page-tiffin.png";
import videoPage from "../assets/img/video-page.png";

const steps = [
  {
    number: "01",
    title: "Browse Local Offerings",
    description:
      "Explore a mouthwatering selection of homemade dishes and restaurant favorites from chefs and eateries in your neighborhood. Search by cuisine, dietary preference, or browse featured options.",
    imgSrc: homePageRestaurant,
  },
  {
    number: "02",
    title: "Place Your Order Directly",
    description:
      "Once you've found the perfect meal, place your order seamlessly through our user-friendly platform. No more dealing with multiple apps or third-party fees - just direct ordering from the source.",
    imgSrc: homePageTiffin,
  },
  {
    number: "03",
    title: "Enjoy Prompt Homemade Delivery",
    description:
      "Sit back and get ready for a delicious homemade meal or restaurant dish delivered straight to your doorstep. With ZipZap's efficient logistics, your order arrives fresh and prompt.",
    imgSrc: videoPage,
  },
];

const HowItWorksSection = () => (
  <section id="how" className="section-how">
    <div className="container mx-auto text-start mb-24">
      <span className="subheading">
        How it works
      </span>
      <h2 className="heading-secondary">
        Homemade Flavors to Your Door in 3 Easy Steps
      </h2>
    </div>
    <div className="container mx-auto grid grid-cols-1">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`step-box ${
            index % 2 === 0 ? "lg:flex-row-reverse" : ""
          } flex flex-col lg:flex-row items-center gap-8 pb-40`}
        >
          <div className="flex justify-center items-center lg:w-1/2 relative">
            <div className="relative hidden md:flex text-center justify-center items-center z-[0] mt-[-15px] container-round">
              <div className="rounded-full h-[150px] w-[150px] bg-[#fdf2e9] absolute z-[9] full-circle2"></div>
              <div className="rounded-full h-[180px] w-[200px] bg-[#fdf2e9ac] absolute z-[8] full-circle3"></div>
              <div className="rounded-full h-[200px] w-[240px] bg-[#fdf2e982] absolute z-[7] full-circle4"></div>
            </div>
            <div className="absolute flex justify-center items-center">
              <img
                src={step.imgSrc}
                alt="app screen"
                className="h-[300px]"
              />
            </div>
          </div>
          <div className="step-text-box">
            <p className="step-number">
              {step.number}
            </p>
            <h3 className="heading-tertiary">
              {step.title}
            </h3>
            <p className="step-description">
              {step.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default HowItWorksSection;
