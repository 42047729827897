import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "tailwindcss/tailwind.css";
import "./App.css";
import logo from "./assets/img/r.png";
import ChefsSection from "./components/ChefsSection";
import FeaturedSection from "./components/FeaturedSection";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import HowItWorksSection from "./components/HowItWorksSection";
import TestimonialsSection from "./components/TestimonialsSection";
import { Link, animateScroll } from "react-scroll";
import { BsArrowUpSquareFill } from "react-icons/bs";
import RestaurantTerms from "./components/RestaurantTerms";
import RestaurantPrivacy from "./components/RestaurantPrivacy";
import EndUserTerms from "./components/EndUserTerms";
import EndUserPrivacy from "./components/EndUserPrivacy";
import DeliveryPartnerTerms from "./components/DeliveryPartnerTerms";
import DeliveryPartnerPrivacy from "./components/DeliveryPartnerPrivacy";
import ContactUs from "./components/ContactUs";
import ContactUsSection from "./components/ContactUsSection";

function App() {
  const [scrollTop, setScrollTop] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setScrollTop(true);
    } else {
      setScrollTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  return (
    <Router>
      <div className="overflow-x-hidden">
        <Header logo={logo} />
        <main>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroSection />
                  <FeaturedSection />
                  <HowItWorksSection />
                  <ChefsSection />
                  <TestimonialsSection />
                  <ContactUsSection />
                </>
              }
            />
            <Route path="/restaurant-terms" element={<RestaurantTerms />} />
            <Route path="/restaurant-privacy" element={<RestaurantPrivacy />} />
            <Route path="/enduser-terms" element={<EndUserTerms />} />
            <Route path="/enduser-privacy" element={<EndUserPrivacy />} />
            <Route
              path="/deliverypartner-terms"
              element={<DeliveryPartnerTerms />}
            />
            <Route
              path="/deliverypartner-privacy"
              element={<DeliveryPartnerPrivacy />}
            />
            <Route path='/contact-us' element={<ContactUs />} />
          </Routes>
        </main>
        <Footer logo={logo} />
        {scrollTop && (
          <Link
            smooth={true}
            duration={1000}
            className="fixed bottom-10 right-10 sm:bottom-5 sm:right-40"
          >
            <button onClick={scrollToTop} className="drop-shadow-2xl opacity-65">
              <BsArrowUpSquareFill className="text-5xl text-warning shadow-inner" />
            </button>
          </Link>
        )}
      </div>
    </Router>
  );
}

export default App;
