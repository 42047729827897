import React from 'react';
import heroImg from '../assets/img/hero.png';

const HeroSection = () => (
  <section className="hero-section">
    <div className="hero">
      <div className="hero-text-box">
        <h1 className="heading-primary">Local Flavors at Your Doorstep</h1>
        <p className="hero-description">
        Enjoy direct orders from your favorite restaurants, skip the middleman, and savor the taste of homemade meals with ZipZap—supporting local businesses while keeping more in your pocket.
        </p>
      </div>
      <div className="hero-image-box">
        <img src={heroImg} alt="banner" className="hero-img" />
      </div>
    </div>
  </section>
);

export default HeroSection;
