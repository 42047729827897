import React from 'react';

const DeliveryPartnerPrivacy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-6">Privacy Policy for ZipZap, Inc.</h1>
      <p className="text-2xl"><strong>Effective Date:</strong> 23-07-2024</p>
      <p className="text-2xl"><strong>Introduction:</strong> ZipZap, Inc. (“we”, “our”, or “us”) values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and share your information when you use our app. This policy is publicly accessible and linked on our app’s store listing page as required by Google Play.</p>
      
      <h2 className="text-4xl font-semibold mt-10 mb-2">1. Information Collection</h2>
      <ul className="list-disc list-inside text-2xl">
        <li><strong>Personal Information:</strong> We collect personal information such as your name, contact details, and payment information for the purpose of processing deliveries and providing you with our services.</li>
        <li><strong>Usage Information:</strong> We collect information about your use of the app, including delivery history, preferences, and other usage statistics.</li>
        <li><strong>Phone Number Detection:</strong> To enhance your user experience, our app may automatically detect your phone number using the <code>android.permission.READ_PHONE_STATE</code> permission. This streamlines the sign-up and login processes.</li>
        <li><strong>Location Information:</strong> We collect and access your real-time location data to track deliveries and improve our services. Location data is used solely for delivery tracking and related purposes.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">2. Use of Information</h2>
      <p className="text-2xl">We use your personal and sensitive information to provide and improve our services, track deliveries in real-time, process payments, and communicate with you regarding deliveries or app-related updates.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">3. Sharing of Information</h2>
      <p className="text-2xl">We do not share your personal information with third parties except when it is required to provide our services (e.g., sharing your location with a delivery partner). We may also share aggregated or anonymized data that does not identify you personally. We comply with legal requests for data as required by law.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">4. Data Security</h2>
      <p className="text-2xl">We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, or misuse. Your data is stored securely, and we use encryption and secure protocols to safeguard your information.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">5. Data Retention</h2>
      <p className="text-2xl">We retain your information only as long as necessary to fulfill the purposes outlined in this policy or to comply with legal obligations. You have the right to request deletion of your data, and we will respond to such requests as required by law.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">6. User Rights</h2>
      <p className="text-2xl">You have the right to access, modify, or delete your personal information at any time. To exercise these rights, please contact us at <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>. We will respond to your inquiry promptly.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">7. Changes to This Policy</h2>
      <p className="text-2xl">We may update this Privacy Policy from time to time to reflect changes in our practices or for legal or regulatory reasons. We will notify you of any significant changes through the app and update the “Effective Date” at the top of this document.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">8. Contact Information</h2>
      <p className="text-2xl">If you have any questions about this Privacy Policy or how your personal information is handled, please contact us at <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>. This is our designated privacy contact.</p>
    </div>
  );
};

export default DeliveryPartnerPrivacy;
