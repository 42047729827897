import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-scroll";

const Header = ({ logo }) => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  return (
    <header className="header">
      <a href="/">
        <img src={logo} alt="logo" className="logo" />
      </a>
      <nav className={`main-nav ${navOpen ? "nav-open" : ""}`}>
        <ul className="main-nav-list">
          <li>
            <Link
              to="how"
              smooth={true}
              duration={500}
              className="main-nav-link"
            >
              How it works
            </Link>
          </li>
          <li>
            <Link
              to="meals"
              smooth={true}
              duration={500}
              className="main-nav-link"
            >
              Restaurants
            </Link>
          </li>
          <li>
            <Link
              to="testimonials"
              smooth={true}
              duration={500}
              className="main-nav-link"
            >
              Testimonials
            </Link>
          </li>
          <li>
            <Link
              to="contact_us"
              smooth={true}
              duration={500}
              className="main-nav-link"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
      <button
        className="btn-mobile-nav"
        onClick={toggleNav}
      >
        <ion-icon
          className="icon-mobile-nav"
          name={`${navOpen ? "close-outline" : "menu-outline"}`}
        />
      </button>
    </header>
  );
};

Header.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default Header;
