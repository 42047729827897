import React from "react";
import PriyanshiSinha from "../assets/img/customers/priyshi_photo.jpeg";
import AshishMewada from "../assets/img/customers/ashish_photo.jpg";
import AbhishekKumar from "../assets/img/customers/abhishek_photo.jpg";
import RahulRanjan from "../assets/img/customers/Rahul-pr-Image.jpeg";
import gallery1 from "../assets/img/gallery/gallery-1.jpg";
import gallery2 from "../assets/img/gallery/gallery-2.jpg";
import gallery3 from "../assets/img/gallery/gallery-3.jpg";
import gallery4 from "../assets/img/gallery/gallery-4.jpg";
import gallery5 from "../assets/img/gallery/gallery-5.jpg";
import gallery6 from "../assets/img/gallery/gallery-6.jpg";
import gallery7 from "../assets/img/gallery/gallery-7.jpg";
import gallery8 from "../assets/img/gallery/gallery-8.jpg";
import gallery9 from "../assets/img/gallery/gallery-9.jpg";

const testimonials = [
  {
    name: "Priyanshi Sinha",
    text: "ZipZap makes dining at home effortless with fast, reliable deliveries and consistently great food. A total game-changer for busy days!",
    imgSrc: PriyanshiSinha,
  },
  {
    name: "Ashish Mewada",
    text: "Every meal from ZipZap arrives fresh and on time. Their service is exceptional and always meets my high standards!",
    imgSrc: AshishMewada,
  },
  {
    name: "Abhishek Kumar",
    text: "Impressed with ZipZap's wide range of options and quick delivery. They make satisfying my cravings easy and enjoyable!",
    imgSrc: AbhishekKumar,
  },
  {
    name: "Rahul Ranjan",
    text: "ZipZap combines convenience with top-notch food quality. I trust them for a hassle-free dining experience every time.",
    imgSrc: RahulRanjan,
  },
];

const galleryImages = [
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  gallery8,
  gallery9,
  // gallery10,
  // gallery11,
  // gallery12,
];

const TestimonialsSection = () => (
  <section id="testimonials" className="section-testimonials">
    <div className="testimonials-container ">
      <span className="subheading ">Testimonials</span>
      <h2 className="heading-secondary ">Once you try it, you can't go back</h2>
      <div className="testimonials ">
        {testimonials.map((testimonial, index) => (
          <figure key={index} className="testimonial">
            <img
              src={testimonial.imgSrc}
              alt={`customer ${testimonial.name}`}
              className="testimonial-img "
            />
            <blockquote className="testimonial-text">
              {testimonial.text}
            </blockquote>
            <p className="testimonial-name ">&mdash; {testimonial.name}</p>
          </figure>
        ))}
      </div>
    </div>
    <div className="gallery ">
      {galleryImages.map((imgSrc, index) => (
        <figure key={index} className="gallery-item">
          <img
            src={imgSrc}
            alt="beautifully arranged food"
          />
        </figure>
      ))}
    </div>
  </section>
);

export default TestimonialsSection;
