import React from 'react';

const EndUserTerms = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-5xl font-bold mb-6">End User Terms of Service</h1>
      <p className="text-2xl"><strong>Introduction:</strong> Welcome to our food and grocery delivery app. By using our services, you agree to the following terms and conditions.</p>
      
      <h2 className="text-4xl font-semibold mt-10 mb-2">Eligibility</h2>
      <p className="text-2xl">You must be at least 18 years old to use this app.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Account Registration</h2>
      <p className="text-2xl">You must register an account to place orders. Ensure your information is accurate and updated.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Ordering and Payment</h2>
      <ul className="list-disc list-inside text-2xl">
        <li><strong>Methods:</strong> Cash on Delivery, UPI</li>
        <li><strong>Process:</strong> Upon placing an order, you agree to pay the total amount specified.</li>
      </ul>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Delivery</h2>
      <p className="text-2xl">We aim to deliver your order within the estimated time, but delays may occur due to unforeseen circumstances.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Returns and Refunds</h2>
      <p className="text-2xl">If you receive incorrect or damaged items, contact support within 24 hours for assistance.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">User Conduct</h2>
      <p className="text-2xl">You agree not to misuse the app or engage in any activities that may harm other users or the service.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Liability</h2>
      <p className="text-2xl">Our liability is limited to the amount paid for your order. We are not responsible for any indirect or consequential losses.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Modifications</h2>
      <p className="text-2xl">We reserve the right to modify these terms at any time. Continued use of the app constitutes acceptance of the changes.</p>
      
      <h2 className="text-4xl font-semibold mt-5 mb-2">Contact Information</h2>
      <p className="text-2xl">For any queries, contact us at <a href="mailto:tech@zipzap.live" className="text-blue-600 underline">tech@zipzap.live</a>.</p>
    </div>
  );
};

export default EndUserTerms;
