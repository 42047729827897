import React, {useState} from 'react';

const ContactUsSection = () => {

    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('http://localhost:3000/helper/create_enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': 'BrowserId=qIsqoWauEe-k9P0H2InQgw; CookieConsentPolicy=0:1; LSKey-c$CookieConsentPolicy=0:1'
                },
                body: JSON.stringify({
                    name,
                    message,
                    phone_number: phoneNumber
                })
            });

            const result = await response.json();
            if (response.ok) {
                window.location.reload();
            } else {
                setStatus(`Error: ${result.message}`);
            }
        } catch (error) {
            setStatus(`Error: ${error.message}`);
        }
    };


    return (
        <section id="contact_us" className="section-contact_us">
            <div className="container mx-auto px-4">
                <form className="w-full max-w-5xl mx-auto bg-white p-8 rounded-lg" method="POST" onSubmit={handleSubmit}>
                    <div className="space-y-8">
                        <div className="pb-8">
                            <h2 className="text-4xl font-bold text-gray-900">Contact Us</h2>
                            <p className="mt-2 text-lg text-gray-600">Our team will get in touch with you based on the information provided.</p>

                            <div className="mt-6 grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-6">
                                <div>
                                    <label htmlFor="username" className="block text-lg font-medium text-gray-900">Name</label>
                                    <input
                                        type="text"
                                        name="username"
                                        id="username"
                                        autoComplete="username"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-4 py-3 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-lg"
                                        placeholder="John Cena"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>

                                <div className='mt-4'>
                                    <label htmlFor="phone_number" className="block text-lg font-medium text-gray-900">Phone Number</label>
                                    <input
                                        type="number"
                                        name="phone_number"
                                        id="phone_number"
                                        autoComplete="phone_number"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-4 py-3 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-lg"
                                        placeholder="638-352-2927"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className="mt-6">
                                <label htmlFor="about" className="block text-lg font-medium text-gray-900">Notes</label>
                                <textarea
                                    id="about"
                                    name="about"
                                    rows="4"
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm px-4 py-3 text-gray-900 placeholder-gray-400 focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50 sm:text-lg"
                                    placeholder="Your message here"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                ></textarea>
                            </div>

                            <div className="mt-8">
                                <button
                                    type="submit"
                                    className="block w-full py-3 px-4 bg-indigo-600 text-white font-semibold rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-lg"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default ContactUsSection;